.b-cnt-img {
  @media screen and (min-width: $screen-sm-min) {
    display: flex; }
  & > div {
    position: relative;
    @media screen and (min-width: $screen-sm-min) {
      flex: 0 0 50%; } } }

.b-cnt-img.b-cnt-img--layout-type-img-left {
  @media screen and (min-width: $screen-sm-min) {
    flex-direction: row-reverse; }
  .b-cnt-img__cnt {
    justify-content: flex-start; }
  .b-cnt-img__cnt-in {
    @media screen and (min-width: $screen-sm-min) {
      padding-left: ($size-site-margins * 3);
      padding-right: $size-site-margins; } } }

.b-cnt-img__cnt {
  padding: 20px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media screen and (min-width: $screen-sm-min) {
    padding: 50px 0; } }

.b-cnt-img__cnt-in {
  padding-right: $size-site-margins;
  padding-left: $size-site-margins;
  @media screen and (min-width: $screen-sm-min) {
    flex: 0 1 (($size-site-max-width/2) + $size-site-margins);
    padding-right: ($size-site-margins * 3); } }

.b-cnt-img__img {
  min-height: 300px; }

.b-cnt-img__img-item {
  @include object-fit(cover);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }
