.how-start {
  padding: 8em 0;
  @media screen and (min-width: $screen-sm-min) {
    padding: 12em 0 8em; } }

.how-start__header {
  text-align: center;

  max-width: 911px;
  margin: 0 auto;
  .c-title--small {
    margin-bottom: 1em; } }

.how-start__table {
  max-width: 1030px;
  margin: 0 auto;
  margin-bottom: 3em; }

.table-line {
  @include fluid-type(14px, 20px); }

.table--first-line, .table--brake {
  display: flex !important;
  align-items: flex-end !important;
  padding: .95em 0 !important;
  p {
    text-transform: uppercase;
    text-align: center;
    line-height: 1.1;
    margin-bottom: 0.4em; }
  strong {
    @include fluid-type(14px, 24px); }
  img {
    width: 50px !important;
    margin-bottom: 0.6em;
    @media screen and (min-width: $screen-sm-min) {
      width: 159px !important; } }
  .table-1 {
    @include fluid-type(14px, 24px);
    font-weight: 700; } }

.table-line {
  display: flex;
  align-items: center;
  @extend %clearfix;
  padding: 1.2em 0 1.2em;
  position: relative;
  img {
    width: 19px; }
  &:before {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    height: 7px;
    width: 100%;
    background: $color-tertiary;
    border-radius: 7px; } }

.table-1 {
  @include column(500/1000, $gutter: 1);
  @media screen and (min-width: $screen-sm-min) {
    @include column(604/1000, $gutter: 1); } }
.table-2 {
  text-align: center !important;
  @include column(250/1000, $gutter: 1);
  @media screen and (min-width: $screen-sm-min) {
    @include column(198/1000, $gutter: 1); } }
.table-3 {
  @include column(250/1000, $gutter: 1);
  text-align: center !important;
  @media screen and (min-width: $screen-sm-min) {
    @include column(198/1000, $gutter: 1); } }



.table-btn {
  text-align: center; }
