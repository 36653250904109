.big-search {
  padding: 12em 0 4em; }

.big-search__wrapper {
  display: flex;
  justify-content: center; }

.big-search__head {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 2em;
  flex-wrap: wrap;
  text-align: center;
  @media screen and (min-width: $screen-xs-min) {
    flex-wrap: nowrap;
    text-align: left;
    margin-bottom: 3.5em; }
  .c-title {
    margin-left: 0;
    margin-top: 1em;
    @media screen and (min-width: $screen-xs-min) {
      margin-left: .5em;
      max-width: 360px;
      margin-top: 0; }
    @media screen and (min-width: $screen-md-min) {
      position: absolute;
      right: -400px;
      top: calc(50% - 60px); } }
  img {
    max-width: 200px;
    @media screen and (min-width: $screen-sm-min) {
      max-width: 270px;
      width: 100%; } } }

.big-search__inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 1075px) {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between; } }

/* Animate for Input Radio */
/*******************************************************************************/
.big-search__radio {
  $wormDur: 0.4s;
  $radioDur: 0.2s;
  $timing1: cubic-bezier(0.45,0.05,0.55,0.95);
  $timing2: cubic-bezier(0.5,0,0.5,2);
  position: relative;
  margin-bottom: 1em;
  @media screen and (min-width: $screen-sm-min) {
    padding: 0 .5em; }
  @media screen and (min-width: 1075px) {
    margin-bottom: 0; }


  form {
  	position: relative; }

  input {
  	position: fixed;
  	top: -.5em;
  	left: -.5em; }


  label {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    transition: color $radioDur $timing1;
    @media screen and (min-width: $screen-sm-min) {
      font-size: 18px; }
    @media screen and (min-width: $screen-md-min) {
      font-size: 22px; }
    &:not(:last-of-type) {
 }      // margin-bottom: .5em
    span {
      box-shadow: 0 0 0 3px currentColor inset;
      display: inline-block;
      margin-right: 0.5em;
      vertical-align: bottom;
      width: 20px;
      height: 20px;
      transition: transform $radioDur $timing2, box-shadow $radioDur $timing1, color $radioDur $timing1; } }

  label span,
  .worm__segment:before {
  	border-radius: 50%; }

  // input:checked + label,
  // input:checked + label span,
  .worm__segment:before {
  	color: $color-main; }

  input:checked + label {
  	&, span {
  		transition-delay: $wormDur; }
  	span {
  		transform: scale(1.1); } }

  .worm {
  	top: 13px;
  	left: 5px;
  	&, .worm__segment {
  		position: absolute; }
  	.worm__segment {
  		top: -5px;
  		left: 0;
  		width: 10px;
  		height: 10px;
  		transition: transform $wormDur $timing1;
    @media screen and (min-width: $screen-sm-min) {
      top: -4px;
      left: 8px; }
    @media screen and (min-width: $screen-md-min) {
      top: 0;
      left: 9px; }
  		&:before {
  			animation-duration: $wormDur;
  			animation-timing-function: $timing1;
  			background: currentColor;
  			content: "";
  			display: block;
  			width: 100%;
  			height: 100%; }
  		// &:first-child, &:last-child
  		// 	&:before
 } }  		// 		box-shadow: 0 0 1em 0 currentColor

  @for $s from 2 through 30 {
  	$delay: $wormDur/100 * ($s - 1);
  	.worm__segment:nth-child(#{$s}) {
  		transition-delay: $delay;
  		&:before {
  			animation-delay: $delay; } } }

  /* States */
  @for $s from 1 through 3 {
    input:nth-of-type(#{$s}):checked ~ .worm .worm__segment {
      @if $s > 1 {
        transform: translateY(26px * ($s - 1)); }
      &:before {
        animation-name: hop#{$s}; } }

    @keyframes hop#{$s} {
      from, to {
        transform: translateX(0); }
      50% {
        transform: translateX(-1em); } } }




  @media screen and (min-width: $screen-sm-min) {
    @for $s from 1 through 3 {
    	input:nth-of-type(#{$s}):checked ~ .worm .worm__segment {
    		@if $s > 1 {
    			transform: translateY(30px * ($s - 1)); }
    		&:before {
    			animation-name: hop#{$s}; } }

    	@keyframes hop#{$s} {
    		from, to {
    			transform: translateX(0); }
    		50% {
    			transform: translateX(-1em); } } } }



  @media screen and (min-width: $screen-md-min) {
    @for $s from 1 through 3 {
    	input:nth-of-type(#{$s}):checked ~ .worm .worm__segment {
    		@if $s > 1 {
    			transform: translateY(2em * ($s - 1)); }
    		&:before {
    			animation-name: hop#{$s}; } }

    	@keyframes hop#{$s} {
    		from, to {
    			transform: translateX(0); }
    		50% {
    			transform: translateX(-1em); } } } } }

/* Inpur Range */
/*******************************************************************************/

.big-search__range {
  margin-bottom: 1em;
  @media screen and (min-width: $screen-sm-min) {
    padding: 0 .5em; }
  @media screen and (min-width: 1075px) {
    margin-bottom: 0; } }

.big-search__range-text {
  @include fluid-type(16px, 22px);
  font-weight: 700;
  span {
    color: $color-main; } }

$height: 2em;

.section-range {
  max-width: 360px; }


.range {
  position: relative;
  width: 100%;
  input {
    border: 0px;
    &:focus, &:active {
      outline: none;
      box-shadow: none; } }
  [type=range] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    outline: none;
    background: transparent;
    &::-webkit-slider-runnable-track {
      height: 7px;
      cursor: pointer;
      animate: 0.2s;
      background: $color-tertiary;
      border-radius: 2px; }
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: 7px solid;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background: transparent;
      cursor: pointer;
      margin-top: -5px; } }
  .input-value {
    position: absolute;
    background: $color-main;
    border-radius: 5px;
    height: 7px;
    width: 23%;
    left: 5%;
    top: 15px; } }

.range-2 {
  [type=range][multiple] {
    display: block;
    height: $height;
    margin: 0;
    pointer-events: none;
    &::-webkit-slider-thumb {
      border-color: $color-main;
      background: $color-main;
      position: relative;
      z-index: 5; }
    & + [type=range][multiple] {
      margin-top: $height * -1;
      &::-webkit-slider-thumb {
        border-color: $color-main; } } }
  span:not(.last) {
    margin-right: 20%; }
  [type=range]::-webkit-slider-thumb {
    pointer-events: all; }
  [type=range]::-moz-range-thumb {
    pointer-events: all; }
  [type=range]::-ms-thumb {
    pointer-events: all; } }

/* */
/*******************************************************************************/

.big-search__select-text {
  @include fluid-type(16px, 22px);
  font-weight: 700;
  text-align: center;
  @media screen and (min-width: $screen-sm-min) {
    text-align: left;
    padding: 0 .5em; } }

.big-search__select {
  margin-bottom: 1em;
  select {
    border: none;

    color: $color-main;
    font-weight: 700;
    @include fluid-type(16px, 22px);
    &:focus {
      outline: none; } }
  @media screen and (min-width: $screen-sm-min) {
    margin-bottom: 0; } }

/* */
/*******************************************************************************/

.big-search__btn {
  @media screen and (min-width: $screen-sm-min) {
    padding: 0 0.5em;
    width: 100%;
    text-align: center;
    margin-top: 1em; }
  @media screen and (min-width: 1270px) {
    width: auto;
    margin-top: 0; } }

/* */
/*******************************************************************************/

.big-search__adv {
  margin-top: 2em;
  a {
    font-size: 12px;
    color: #000;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      color: $color-main;
      text-decoration: none; }
    img {
      margin-left: 5px; } } }
