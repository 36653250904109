.u-text-color-main {
  color: $color-main; }

.u-text-color-secondary {
  color: $color-secondary; }

.u-text-color-black {
  color: #000; }

.u-text-color-white {
  color: #FFF; }
