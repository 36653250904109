.container-fluid,
.wrap,
.site-main,
.o-wrap {
  @include center($size-site-outer-width, $size-site-margins);
  position: relative; }

.wrap--narrow,
.o-wrap--narrow {
  @include center(700px, 20px); }
