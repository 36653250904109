.pair {
  margin: 5em 0; }

.pair__wrapper {
  @include column(1/1); }

.pair__col {
  @media screen and (min-width: $screen-sm-min) {

    @include column(1/3); }
  h2 {
    margin-bottom: 1em; } }

.pair__range {
  margin-bottom: 2em !important; }

.pair__btn {
  text-align: center; }
