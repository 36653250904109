.header-panel {
  .o-wrap {
    max-width: 1540px; } }

.header-panel__links {
  padding-top: 160px;
  padding-left: 0;
  list-style: none;
  display: flex;
  // flex-direction: column
  justify-content: center;
  flex-wrap: wrap;
  a {
    width: 100%;
    text-align: center;
    display: inline-block;
    @media screen and (min-width: $screen-sm-min) {
      width: auto; } }
  .c-btn--panel {
    font-size: 14px;
    text-transform: uppercase;
    padding: .5em 1.35em;
    border: 1px solid $color-main;
    font-weight: 700;
    min-width: 195px;
    font-family: $font-main !important;
    &:hover {
      background: $color-main;
      color: #fff;
      text-decoration: none; }
    @media screen and (min-width: 1540px) {
      min-width: auto; } }
  .header-panel--current {
    background: $color-main;
    color: #fff; } }
