.c-horizontal-line {
  border: 0;
  border-bottom: 1px solid #C5C5C5;
  margin: 0;
  padding: 0;
  background: transparent; }

.c-horizontal-line--xs {
  @media screen and (min-width: $screen-sm-min) {
    display: none; } }

.c-horizontal-line--sm {
  display: none;
  @media screen and (min-width: $screen-sm-min) {
    display: block; }
  @media screen and (min-width: $screen-md-min) {
    display: none; } }

.c-horizontal-line--md {
  display: none;
  @media screen and (min-width: $screen-md-min) {
    display: block; }
  @media screen and (min-width: $screen-lg-min) {
    display: none; } }

.c-horizontal-line--lg {
  display: none;
  @media screen and (min-width: $screen-lg-min) {
    display: block; }
  @media screen and (min-width: $screen-xl-min) {
    display: none; } }

.c-horizontal-line--xl {
  display: none;
  @media screen and (min-width: $screen-xl-min) {
    display: block; } }
