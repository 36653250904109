.search-results__header {
  text-align: center;
  margin-bottom: 3em; }

.search-results__el {
  @include column(1/1);
  text-align: center;
  margin-bottom: 3em;
  @media screen and (min-width: $screen-sm-min) {
    @include column(1/2, $cycle: 2);
    text-align: center; }
  @media screen and (min-width: $screen-md-min) {
    @include column(1/3, $cycle: 3);
    text-align: center; } }


.status--online {
  margin-top: 0.5em;
  margin-bottom: 0.8em;
  color: $color-main;
  position: relative;
  padding-left: 25px;
  display: inline-block;
  &:before {
    content: '';
    background: $color-main;
    height: 13px;
    width: 13px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 7px; } }

.status--offline {
  margin-top: 0.5em;
  margin-bottom: 0.8em;
  color: #3f819b;
  position: relative;
  padding-left: 25px;
  display: inline-block;
  &:before {
    content: '';
    background: #3f819b;
    height: 13px;
    width: 13px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 7px; } }

.search-results__cols {
  @include column(1/1);
  margin-bottom: 6em; }
