.slider-people {
  padding: 4em 0;
  .slick-arrow {
    text-indent: -99999999px;
    border: 0;
    width: 23px;
    height: 37px;
    position: absolute;
    top: calc(40% - 16px);
    background-color: transparent;
    background-size: cover;
    transition: ease-in-out 0.3s;
    z-index: 9999;
    &:focus {
      outline: none; } }
  .slick-next {
    right: 0;
    background-image: url('../img/icons/arrow-right.svg');
    &:hover {
      transform: translateX(5px); } }
  .slick-prev {
    left: 0;
    background-image: url('../img/icons/arrow-left.svg');
    &:hover {
      transform: translateX(-5px); } } }

.slider-people__wrapper {
  margin-top: 3.4em; }

.slider-people__title {
  text-align: center; }

.slider-people__el {
  text-align: center; }

.slider-people__image {
  position: relative;
  max-width: 175px;
  margin: 0 auto;
  img {
    border-radius: 50%;
    position: relative; } }

.image--female {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    height: 32px;
    width: 32px;
    background-image: url('../img/icons/female.svg');
    background-size: cover;
    top: 0;
    left: 22px;
    z-index: 999; } }

.image--male {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    height: 28px;
    width: 32px;
    background-size: cover;
    background-image: url('../img/icons/male.svg');
    top: 0;
    left: 22px;
    z-index: 999; } }

.slider-people__name {
  margin-top: .7em;
  font-weight: 700;
  @include fluid-type(16px, 18px); }



.text--famale {
  color: $color-main; }
.text--male {
  color: #3f819b; }
