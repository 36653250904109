.u-hidden-on-mobile {
  @media (max-width: $screen-xs-max) {
    display: none; } }

.u-hidden-on-tablet {
  @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
    display: none; } }

.u-hidden-on-desktop {
  @media (min-width: $screen-lg-min) {
    display: none; } }
