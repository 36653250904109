// Basic colors
$color-main:                  #a92826;
$color-secondary:             #cbe9f5;
$color-tertiary:              #b6ddec;
$color-text:                  #000000;
$color-site-background:       #ffffff;
$color-headers:               #000000;
$brand-primary:               $color-main;
$color-link:                  $color-main;
$color-link-hover:            darken($color-main, 10%);

// Sizing
$size-line-height:            1.65;
$size-line-height-header:     1.41;
$size-text-min:               16px;
$size-text-max:               18px;
$size-block-el-bottom-margin: 1.7em;

// Grid
$size-site-max-width:         1360px;
$size-site-margins:           20px;
$size-site-outer-width:       $size-site-max-width + ($size-site-margins * 2);
$grid-columns:                12;
$grid-gutter-width:           30px;

// Fonts
$font-weight-standard:        400;
$font-weight-header:          700;

// Fluid Typography variables
$min-vw:                      320px;
$max-vw:                      1200px;

// Fonts
$font-main:                   'Nunito', sans-serif;
$font-header:                 $font-main;

// AOS
$aos-distance:                20px;

// Font Awesome
$fa-font-path:                '../fonts';

// Breakpoints
$grid-breakpoints: (tiny: 0, mini: 400px, xs: 576px, sm: 768px, md: 992px, lg: 1200px, xl: 1400px,  xxl: 1800px);

$screen-mini-min:            map-get($grid-breakpoints, mini);
$screen-xs-min:              map-get($grid-breakpoints, xs);
$screen-sm-min:              map-get($grid-breakpoints, sm);
$screen-md-min:              map-get($grid-breakpoints, md);
$screen-lg-min:              map-get($grid-breakpoints, lg);
$screen-xl-min:              map-get($grid-breakpoints, xl);
$screen-xxl-min:             map-get($grid-breakpoints, xxl);

$screen-mini-max:            (map-get($grid-breakpoints, xs) - 1);
$screen-xs-max:              (map-get($grid-breakpoints, sm) - 1);
$screen-sm-max:              (map-get($grid-breakpoints, md) - 1);
$screen-md-max:              (map-get($grid-breakpoints, lg) - 1);
$screen-lg-max:              (map-get($grid-breakpoints, xl) - 1);
$screen-xl-max:              (map-get($grid-breakpoints, xxl) - 1);
