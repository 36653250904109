.carousel-load-fix {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: all 0.3s cubic-bezier(.35,1,.33,1); }

.carousel-load-fix.slick-initialized {
  position: relative;
  left: auto;
  top: auto;
  opacity: 1; }

// Remove outline from slides
.slick-slide,
.slick-slide * {
  outline: none !important; }
