.video-container {
  margin-bottom: $size-block-el-bottom-margin;
  margin-top: 0;
  float: none;
  clear: both;
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  &:last-child {
    margin-bottom: 0; }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; } }
