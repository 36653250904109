.alert-success {
  background: lighten(green, 60%);
  text-align: center;
  padding: 1em 2em;
  border: 1px solid green;
  margin-top: 0.4em; }

.alert {
  background: lighten(red, 40%);
  text-align: center;
  padding: .7em 2em;
  border: 1px solid red;
  margin-top: 0.4em; }

.alert-center {
  margin: 0 auto;
  text-align: center; }

.alert-short {
  background: lighten(red, 40%);
  text-align: 16px;
  padding: .7em 2em;
  border: 1px solid red;
  display: inline-block; }

.alert-short-success {
  background: lighten(green, 60%);
  text-align: 16px;
  padding: .7em 2em;
  border: 1px solid green;
  display: inline-block; }
