.contact {
  padding: 8em 0;
  @media screen and (min-width: $screen-sm-min) {
    padding: 12em 0 8em; }
  .o-wrap {
    max-width: 1100px; } }

.contact__header {
  text-align: center;
  margin-bottom: 3.75em; }

.contact__cnt {
  @media screen and (min-width: $screen-sm-min) {
    padding-top: 2.3em;
    @include column(525/1000); } }

.contact__form {
  margin-top: 3em;
  @media screen and (min-width: $screen-sm-min) {
    @include column(475/1000);
    margin-top: 0; } }

.contact__form-box {
  background: $color-main;
  padding: 1.5em 1em;
  label {
    color: #fff; }
  @media screen and (min-width: $screen-sm-min) {
    padding: 2.15em 2.4em; } }


.contact__form-box-btn {
  text-align: center; }

.contact__cnt-facebook {
  img {
    width: 109px; } }
