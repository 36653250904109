p {
  margin-top: 0;
  margin-bottom: $size-block-el-bottom-margin; }

.text--22 {
  font-weight: 700;
  margin-bottom: 0;
  @include fluid-type(20px, 22px); }

.text-center {
  text-align: center; }

.text-mb3 {
  margin-bottom: 3em; }

.text-mt3 {
  margin-top: 3em; }
