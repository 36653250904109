.login {
  padding: 8em 0;
  .login__box {
    border: 2px solid $color-main;
    padding: 2.1em 2em 1.05em;
    max-width: 490px;
    margin: 0 auto;
    @media screen and (min-width: $screen-sm-min) {
      padding: 2.1em 2.7em 1.05em; } }
  label {
    @include fluid-type(18px, 24px);
    font-weight: 400; }
  @media screen and (min-width: $screen-sm-min) {
    padding: 12em 0 8em; } }


.login__header {
  text-align: center;
  margin-bottom: 3.6em; }

.login__box-btns {
  text-align: center;
  a, p {
    font-size: 16px; }
  .c-btn--wide {
    font-size: 14px;
    margin-bottom: 1.5em; }
  .login-box__reset {
    margin-bottom: 1.2em; } }
