.b-full-width-img {
  @extend %clearfix; }

.b-full-width-img__wrap {
  position: relative;
  height: 200px;
  @media screen and (min-width: $screen-sm-min) {
    height: 300px; }
  @media screen and (min-width: $screen-md-min) {
    height: 400px; }
  @media screen and (min-width: $screen-lg-min) {
    height: 450px; }
  @media screen and (min-width: $screen-xl-min) {
    height: 500px; } }

.b-full-width-img--cols-2 {
  .b-full-width-img__wrap {
    @media screen and (min-width: $screen-xs-min) {
      @include span(1/2); } } }

.b-full-width-img--cols-3 {
  .b-full-width-img__wrap {
    @media screen and (min-width: $screen-xs-min) {
      @include span(1/3); } } }

.b-full-width-img__img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  @include object-fit(cover); }
