// Clearfix
// Clearfix is used for setting correct height to parent element with
// floated elements inside
//
// How to use:
// .example
//   @extend %clearfix

%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both; } }
