.regulation-page {
  padding: 8em 0;
  text-align: center;
  .o-wrap {
    max-width: 925px; }
  p {
    font-size: 14px; }
  .c-title--small {
    margin-bottom: 1.4em; }
  @media screen and (min-width: $screen-sm-min) {
    padding: 12em 0 8em; } }
