
.slider-image-text__header {
  text-align: center;
  margin-bottom: 2em; }

.slider-image-text {
  padding: 8em 0;
  .o-wrap {
    max-width: 1171px; }
  @media screen and (min-width: $screen-sm-min) {
    padding: 12em 0 8em; }
  .slick-arrow {
    text-indent: -99999999px;
    border: 0;
    width: 23px;
    height: 37px;
    position: absolute;
    top: 100px;
    background-color: transparent;
    background-size: cover;
    transition: ease-in-out 0.3s;
    z-index: 9999;
    &:focus {
      outline: none; } }
  .slick-next {
    right: 0px;
    background-image: url('../img/icons/arrow-right.svg');
    &:hover {
      transform: translateX(5px); }
    @media screen and (min-width: $screen-sm-min) {
      right: -20px; }
    @media screen and (min-width: $screen-lg-min) {
      right: -40px; } }
  .slick-prev {
    left: 0px;
    background-image: url('../img/icons/arrow-left.svg');
    &:hover {
      transform: translateX(-5px); }
    @media screen and (min-width: $screen-sm-min) {
      right: -20px; }
    @media screen and (min-width: $screen-lg-min) {
      left: -40px; } } }

.slider-image-text__el {
  padding-top: 1.5em; }

.slider-image-text__img {
  @media screen and (min-width: $screen-sm-min) {
    @include column(250/1000, $cycle: 2); } }



.slider-image-text__text {
  @media screen and (min-width: $screen-sm-min) {
      @include column(750/1000, $cycle: 2); }
  font-size: 16px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 137px;
    height: 125px;
    background-repeat: no-repeat;
    background-image: url('../img/icons/quote.svg');
    background-size: 137px;
    z-index: -1;
    @media screen and (min-width: $screen-sm-min) {
      top: -20px;
      left: -50px; } } }
