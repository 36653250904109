// Scroll to top element
.o-scroll-to-top {
  position: fixed;
  right: 10px;
  bottom: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transition: all 0.6s ease;
  background: $brand-primary;
  color: #fff;
  text-decoration: none;
  text-align: center;
  z-index: 999;
  width: 50px;
  height: 50px;
  transform: translateY(100px);
  transition: all 0.5s cubic-bezier(0.23, 0.3, 0.24, 1.18);
  font-size: 30px;
  border: 2px solid #fff;
  svg {
    width: 23px;
    height: 23px;
    fill: #fff; }
  &:hover {
    background: darken($brand-primary, 10%);
    color: #fff;
    text-decoration: none; } }

.o-scroll-to-top.show {
  visibility: visible;
  transform: translateY(0);
  cursor: pointer;
  opacity: 1.0; }

.svg-test {
  svg {
    width: 90px;
    height: 90px; } }
