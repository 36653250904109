/**
  * Font Face
  * Use local fonts to avoid GDPR problems and improve performance
  * Google fonts can be found here: https://google-webfonts-helper.herokuapp.com/fonts
  * Remember to add font-display: swap to generate FOUT
 */

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/nunito-v13-latin-ext_latin-regular.eot');
  src: local('Nunito Regular'), local('Nunito-Regular'), url('../fonts/nunito-v13-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/nunito-v13-latin-ext_latin-regular.woff2') format('woff2'), url('../fonts/nunito-v13-latin-ext_latin-regular.woff') format('woff'), url('../fonts/nunito-v13-latin-ext_latin-regular.ttf') format('truetype'), url('../fonts/nunito-v13-latin-ext_latin-regular.svg#Nunito') format('svg'); }

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/nunito-v13-latin-ext_latin-700.eot');
  src: local('Nunito Bold'), local('Nunito-Bold'), url('../fonts/nunito-v13-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), url('../fonts/nunito-v13-latin-ext_latin-700.woff2') format('woff2'), url('../fonts/nunito-v13-latin-ext_latin-700.woff') format('woff'), url('../fonts/nunito-v13-latin-ext_latin-700.ttf') format('truetype'), url('../fonts/nunito-v13-latin-ext_latin-700.svg#Nunito') format('svg'); }


@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/nunito-v13-latin-ext_latin-200.eot');
  src: local('Nunito ExtraLight'), local('Nunito-ExtraLight'), url('../fonts/nunito-v13-latin-ext_latin-200.eot?#iefix') format('embedded-opentype'), url('../fonts/nunito-v13-latin-ext_latin-200.woff2') format('woff2'), url('../fonts/nunito-v13-latin-ext_latin-200.woff') format('woff'), url('../fonts/nunito-v13-latin-ext_latin-200.ttf') format('truetype'), url('../fonts/nunito-v13-latin-ext_latin-200.svg#Nunito') format('svg'); }

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/nunito-v13-latin-ext_latin-300.eot');
  src: local('Nunito Light'), local('Nunito-Light'), url('../fonts/nunito-v13-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'), url('../fonts/nunito-v13-latin-ext_latin-300.woff2') format('woff2'), url('../fonts/nunito-v13-latin-ext_latin-300.woff') format('woff'), url('../fonts/nunito-v13-latin-ext_latin-300.ttf') format('truetype'), url('../fonts/nunito-v13-latin-ext_latin-300.svg#Nunito') format('svg'); }

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/nunito-v13-latin-ext_latin-900.eot');
  src: local('Nunito Black'), local('Nunito-Black'), url('../fonts/nunito-v13-latin-ext_latin-900.eot?#iefix') format('embedded-opentype'), url('../fonts/nunito-v13-latin-ext_latin-900.woff2') format('woff2'), url('../fonts/nunito-v13-latin-ext_latin-900.woff') format('woff'), url('../fonts/nunito-v13-latin-ext_latin-900.ttf') format('truetype'), url('../fonts/nunito-v13-latin-ext_latin-900.svg#Nunito') format('svg'); }
