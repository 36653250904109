.user-profile {
  padding-top: 8em;
  @media screen and (min-width: $screen-sm-min) {
    padding-top: 12em; } }

.user-profile__header {
  text-align: center;
  margin-bottom: 4em; }


.user-profile__image-wrapper {
  margin: 0 auto;
  @media screen and (min-width: $screen-sm-min) {
    margin: 0; } }


.user-profile__image {
  @include column(1/1);
  @media screen and (min-width: $screen-sm-min) {
    @include column(450/1000); }
  @media screen and (min-width: $screen-md-min) {
    @include column(300/1000); }
  img {
    border-radius: 50%;
    max-width: 352px;
    width: 100%; }
  .user-profile__image-wrapper {
    position: relative;
    max-width: 352px; }
  .user-profile--female {
    &:before {
      content: '';
      position: absolute;
      top: 0px;
      left: 70px;
      height: 32px;
      width: 32px;
      background-image: url(../img/icons/female.svg);
      background-size: 32px;
      background-position: center;
      background-repeat: no-repeat;
      @media screen and (min-width: $screen-md-min) {
        left: 90px; } } }
  .user-profile--male {
    &:before {
      content: '';
      position: absolute;
      top: 0px;
      left: 70px;
      height: 32px;
      width: 32px;
      background-image: url(../img/icons/male.svg);
      background-size: 32px;
      background-position: center;
      background-repeat: no-repeat;
      @media screen and (min-width: $screen-md-min) {
        left: 90px; } } }
  .user-profile--normal {
    &:after {
      content: '';
      position: absolute;
      bottom: 10px;
      right: 25px;
      height: 71px;
      width: 71px;
      background-image: url(../img/icons/konto_portowe.svg);
      background-size: 71px; } }
  .user-profile--premium {
    &:after {
      content: '';
      position: absolute;
      bottom: 10px;
      right: 25px;
      height: 71px;
      width: 71px;
      background-image: url(../img/icons/konto_nawigacyjne.svg);
      background-size: 71px; } } }

.user-profile__cnt {
  @include column(1/1);
  @media screen and (min-width: $screen-sm-min) {
    @include column(550/1000); }
  @media screen and (min-width: $screen-md-min) {
    @include column(700/1000); }
  margin-bottom: 5em;
  .status--online, .status--offline {
    margin-top: 0; } }

.user-profile__cnt-wrapper {
  @extend %clearfix;
  margin-top: 1.3em; }

.user-profile__cnt-left, .user-profile__cnt-right {
  @include column(1/1);
  @media screen and (min-width: $screen-md-min) {
    @include column(1/2, $cycle: 2, $gutter: 5); } }

.user-profile__el {
  margin-bottom: 1em;
  .c-title {
    position: relative;
    padding-bottom: 1em;
    margin-bottom: 1.15em;
    &:before {
      content: '';
      position: absolute;
      bottom: -4px;
      left: 0;
      height: 7px;
      width: 100%;
      background: $color-tertiary;
      border-radius: 7px; } } }

.user-profile__description {
  @include column(1/1);
  margin-bottom: 8em;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: -20px;
    left: -40px;
    width: 137px;
    height: 125px;
    background-repeat: no-repeat;
    background-image: url(../img/icons/quote.svg);
    background-size: 137px;
    z-index: -1; } }

.user-profile__btns {
  margin-top: 1em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media screen and (min-width: $screen-sm-min) {
    justify-content: flex-start; } }

.user-profile__btn {
  margin-bottom: .5em;
  margin-right: 0.3em;
  text-align: center;
  @media screen and (min-width: $screen-sm-min) {
    text-align: left; } }
