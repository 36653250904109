h1, h2, h3, h4, h5, h6 {
  margin-top: 0; }

h1 {
  @include fluid-type(20px, 29px); }

h2 {
  @include fluid-type(26px, 36px); }

h3 {
  @include fluid-type(24px, 32px); }

h4 {
  @include fluid-type(22px, 28px); }

h5 {
  @include fluid-type(20px, 24px); }

h6 {
  @include fluid-type(18px, 20px); }
