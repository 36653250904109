.u-text-weight-100 {
  font-weight: 100; }

.u-text-weight-300,
.u-text-weight-thin {
  font-weight: 300; }

.u-text-weight-400,
.u-text-weight-normal {
  font-weight: 400; }

.u-text-weight-500 {
  font-weight: 500; }

.u-text-weight-700,
.u-text-weight-bold {
  font-weight: 700; }

.u-text-weight-900 {
  font-weight: 900; }
