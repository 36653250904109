.u-element-mt-0 {
  margin-top: 0; }

.u-element-mb-0 {
  margin-bottom: 0; }

.u-element-m-0 {
  margin: 0; }

.u-element-pt-0 {
  padding-top: 0; }

.u-element-pb-0 {
  padding-bottom: 0; }

.u-element-p-0 {
  padding: 0; }
