.c-btn, .btn, .btn-primary {
  @include fluid-type(14px, 14px);
  text-transform: uppercase;
  font-family: $font-main;
  font-weight: normal;
  display: inline-block;
  background: $color-main;
  padding: 1em 2.5em;
  color: #fff;
  border: 0;
  text-decoration: none;
  font-weight: 800;
  cursor: pointer;
  border-radius: 27px;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  transition: 0.3s;
  &:hover,
  &.btn--hover {
    transform: translateY(-5px);
    text-decoration: none;
    color: #fff;
    background: $color-main; }
  &:focus,
  &:active,
  &:active:focus {
    outline: none; }
  &[disabled] {
    background: #ccc;
    color: #000;
    cursor: not-allowed !important; } }

.c-btn--wide {
  padding: 1.2em 4em;
  @media screen and (min-width: $screen-sm-min) {
    padding: 1.2em 6.5em; } }

.c-btn--alt, .btn-secondary {
  background: transparent;
  color: $color-main;
  border: 2px solid $color-main;
  &:hover {
    color: $color-main;
    background: transparent; } }

.c-btn--full {
  max-width: 260px;
  width: 100%;
  text-align: center; }

.c-btn--cookie {
  padding: 0.3em 0.8em 0.3em 0.8em;
  @include fluid-type(16px, 18px);
  margin: 0 5px 5px 0; }

.c-btn--mini {
  padding: 0.1em 0.4em;
  @include fluid-type(12px, 13px); }

.c-btn--form {
  background-color: #fb605e;
  padding: 1.1em 2em;
  @media screen and (min-width: $screen-sm-min) {
    padding: 1.1em 4.3em; } }
