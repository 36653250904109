#site-wrapper {
  position: relative;
  overflow: hidden !important;
  width: 100%; }

#site-canvas {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
  transition: .3s ease all; }

// Off canvas
#site-menu {
  height: 100%;
  position: absolute;
  top: 0;
  right: -270px;
  background: #fff;
  overflow-y: auto;
  width: 270px;
  z-index: 100;
  border-left: 1px solid $color-main;
  color: $color-text;
  @media (min-width: $screen-xs-min) {
    width: 400px;
    right: -400px; } }

#site-wrapper.show-nav #site-canvas,
body.show-nav .page-wrap {
  transform: translateX(-270px);
  transform: translateX(-270px) tranlateZ(0);
  transform: translate3d(-270px, 0, 0);
  @media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
    transform: translateX(-400px);
    transform: translateX(-400px) tranlateZ(0);
    transform: translate3d(-400px, 0, 0); } }

.row,
.grid,
.group,
.o-row,
.o-grid,
.o-group {
  @extend %clearfix; }

.site-main {
  padding-top: 2em;
  padding-bottom: 2em;
  min-height: 500px; }

// Class generated by WordPress which makes element full width
.o-element-align--full, .alignfull {
  margin-left  : calc( -100vw / 2 + 100% / 2 );
  margin-right : calc( -100vw / 2 + 100% / 2 );
  max-width    : 100vw; }

.alignfull {
  iframe {
    width: 100%;
    height: auto;
    @media screen and (min-width: $screen-md-min) {
      height: 500px; } } }

.o-element-align--wide, {
  @media (min-width: $screen-xl-min) {
    margin-left  : -80px;
    margin-right : -80px; } }

.alignwide {
  max-width: 1180px;
  margin-right: auto;
  margin-left: auto;
  iframe {
    width: 100%;
    height: 250px;
    @media screen and (min-width: $screen-sm-min) {
      height: 350px; }
    @media screen and (min-width: $screen-md-min) {
      height: 640px; } } }

.o-element-align--none {
  .o-wrap {
    padding-left: 0;
    padding-right: 0; } }

.acf-block-preview .o-wrap {
  min-height: 100%; }
