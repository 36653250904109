.edit-profile {
  margin: 5em 0; }


.edit-profile__wrapper {
  @extend %clearfix; }

.edit-profile__el {
  margin-bottom: 2em;
  h2 {
    margin-bottom: .6em; }
  @media screen and (min-width: $screen-sm-min) {
    @include column(1/2, $cycle: 2); }
  @media screen and (min-width: $screen-md-min) {
    @include column(1/4, $cycle: 4); } }

.edit-profile--select2 {
  @media screen and (min-width: $screen-sm-min) {
    margin-top: 2.5em; } }
