.numbers {
  color: $color-main;
  padding: 2em 0;
  .o-wrap {
    max-width: 931px;
    @media screen and (min-width: $screen-sm-min) {
      display: flex;
      justify-content: space-between;
      padding: 4.2em 0 6.3em; } } }

.numbers__slash {
  display: none;
  @include fluid-type(35px, 80px);
  @media screen and (min-width: $screen-sm-min) {
    display: block;
    @include fluid-type(30px, 80px);
    line-height: 1.3; } }

.numbers__el {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1em;
  @media screen and (min-width: $screen-sm-min) {
    margin-bottom: 0; }
  strong {
    @include fluid-type(30px, 80px);
    line-height: 1.3; }
  span {
    font-size: 14px; }
  // &:after
  //   content: '/'
  //   position: absolute
  //   font-size: 80px
  //   font-weight: 200
  //   top: -10px
 }  //   right: -10%
