.o-site-footer {
  padding: 2em 0;
  background: $color-main;
  color: #fff;
  text-align: center;
  @media screen and (min-width: $screen-sm-min) {
    padding: 6.5em 0 4em; } }

.o-site-footer__logos {
  margin-bottom: 3.9em;
  img {
    margin: 0 2em; }
  .logo1 {
    width: 212px; }
  .logo2 {
    width: 84px; } }

.o-site-footer__menu {
  ul {
    list-style: none;
    padding-left: 0;
    li {
      display: block;
      padding: 0 20px;
      @media screen and (min-width: $screen-sm-min) {
        display: inline-block; }
      a {
        color: $color-site-background;
        font-size: 14px;
        font-weight: 700;
        font-family: $font-main !important; } }
    .current-menu-item {
      a {
        color: #fb605e;
        font-weight: 700; } } } }

.o-site-footer__facebook {
  padding: 1.5em 0;
  @media screen and (min-width: $screen-sm-min) {
    padding: 1.8em 0 4.8em; }
  a {
    font-size: 24px;
    color: #fb605e;
    font-weight: 800;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    img {
      margin-right: .3em; } } }

.o-site-footer__copy {
  p {
    font-size: 12px;
    margin-bottom: 0.9em; } }
