ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: $size-block-el-bottom-margin; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

li {
  margin-bottom: 0;
  > ul,
  > ol {
    margin-bottom: 0;
    margin-left: 1rem;
    margin-top: .5rem; } }

dt {
  font-weight: bold; }

dd {
  margin-bottom: 1rem;
  margin-left: 0; }
