input[type="text"],
input[type="password"],
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="range"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="week"],
textarea,
.input--standard {
  border: 2px solid $color-main;
  appearance: none;
  border-radius: 0;
  width: 100%;
  padding: .5em 0.5em;
  font-weight: normal;
  line-height: $size-line-height;
  margin-top: 0.6em;
  &:focus {
    outline: 0;
    box-shadow: inset 0 0 5px rgba(#000, 0.4); }
  &[disabled] {
    background: #ccc;
    color: #000;
    cursor: not-allowed !important; } }

input[type="checkbox"], input[type="radio"], .input--checkbox {
  &:focus {
    outline: 0;
    box-shadow: 0 0 5px rgba($color-main, 0.9); } }

textarea,
.input--textarea {
  height: auto;
  resize: none;
  height: 233px; }


label {
  font-weight: bold;
  @include fluid-type(18px, 24px);
  font-weight: 400; }

.input--line {
  margin-bottom: 1.05em;
  p {
    margin-bottom: 0; }
  form {
    p {
      display: flex;
      line-height: 1.1;
      align-items: center;
      margin-bottom: 0; }
    label {
      font-size: 16px; } }
  select {
    font-weight: 700;
    color: $color-main;
    background-image: url('../img/icons/arrow-bottom.svg');
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: center right 10px; } }


/* Base for label styling */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  // position: absolute
 }  // left: -9999px

[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  position: relative;
  padding-left: 45px;
  cursor: pointer; }


/* checkbox aspect */
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 29px;
  height: 29px;
  border: 2px solid #ccc;
  background-color: #fff;
  box-shadow: inset 0 1px 3px rgba(0,0,0,.1); }

/* checked mark aspect */
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
  content: '';
  background-image: url('../img/icons/tick.svg');
  position: absolute;
  top: .15em;
  left: .22em;
  line-height: 0.8;
  color: $color-main;
  transition: all .2s; }

/* checked mark aspect changes */
[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0); }

[type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1); }

/* disabled checkbox */
[type="checkbox"]:disabled:not(:checked) + label:before,
[type="checkbox"]:disabled:checked + label:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
  cursor: not-allowed; }

[type="checkbox"]:disabled:checked + label:after {
  color: #999;
  cursor: not-allowed; }

[type="checkbox"]:disabled + label {
  color: #aaa;
  cursor: not-allowed; }

/* accessibility */
[type="checkbox"]:checked:focus + label:before,
[type="checkbox"]:not(:checked):focus + label:before {
  border: 2px solid $color-main !important; }


/* hover style just for information */
label:hover:before {
  border: 2px solid $color-main !important; }

/* Useless styles, just for demo design */

/* Base for label styling */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px; }

[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  position: relative;
  padding-left: 40px;
  cursor: pointer; }

/* checkbox aspect */
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 29px;
  height: 29px;
  border: 2px solid $color-main;
  background-color: #fff;
  box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
  transition: 0.1s; }

[type="checkbox"]:checked + label:before {
  background-image: url('../img/icons/tick.svg');
  background-size: 11px;
  background-position: center;
  background-repeat: no-repeat; }

/* checked mark aspect */
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
  content: '';
  position: absolute;
  top: .15em;
  left: .22em;
  font-size: 1em;
  line-height: 0.8;
  color: $color-main;
  transition: all .2s; }

/* checked mark aspect changes */
[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0); }

[type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1); }

/* disabled checkbox */
[type="checkbox"]:disabled:not(:checked) + label:before,
[type="checkbox"]:disabled:checked + label:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
  cursor: not-allowed; }

[type="checkbox"]:disabled:checked + label:after {
  color: #999;
  cursor: not-allowed; }

[type="checkbox"]:disabled + label {
  color: #aaa;
  cursor: not-allowed; }

/* accessibility */
[type="checkbox"]:checked:focus + label:before,
[type="checkbox"]:not(:checked):focus + label:before {
  border: 2px solid $color-main !important; }


/* hover style just for information */
label:hover:before {
  border: 2px solid $color-main !important; }

form > p > label {
  min-height: 29px;
  display: flex;
  align-items: center; }
