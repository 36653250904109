img {
  vertical-align: middle;
  max-width: 100%;
  height: auto; }

figure {
  margin: 0; }

object,
iframe,
embed,
canvas,
video,
audio {
  max-width: 100%; }
