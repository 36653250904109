.call-to-action {
  padding: 3em 0;
  text-align: center;
  .o-wrap {
    max-width: 912px; } }

.call-to-action__image {
  margin-top: 1.5em;
  margin-bottom: 1.2em; }

.call-to-action__btn {
  text-align: center; }
