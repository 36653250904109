.c-pagination {
  margin-top: 20px;
  padding-bottom: 20px;
  @media (min-width: $screen-sm-min) {
    margin-top: 50px;
    padding-bottom: 50px; }
  .page-numbers {
    display: inline-block;
    border: 2px solid $color-main;
    min-width: 40px;
    text-align: center;
    border-radius: 15px;
    line-height: 44px;
    margin-right: 6px;
    padding: 2px 14px 0;
    text-decoration: none;
    color: $color-text;
    font-weight: bold;
    font-size: 20px;
    background: #fff;
    transition: all 0.2s ease-in-out;
    &:hover {
      border-color: darken($color-main, 10%); }
    &.current {
      color: #fff;
      background: $color-main; } } }
