.o-site-header {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  z-index: 999;
  position: absolute;
  @extend %clearfix;
  // position: absolute
  // .o-wrap
  //   display: flex
  //   justify-content: space-between
  //   align-items: center
  @media screen and (min-width: $screen-sm-min) {
    padding-top: 30px;
    padding-bottom: 20px; } }

.o-site-header__logo {
  color: $color-main;
  font-weight: bold;
  font-size: 32px;
  text-decoration: none;
  float: left;
  &:hover {
    text-decoration: none; }
  svg {
    width: 230px;
    height: 30px;
    fill: $color-main;
    .icn--logo-letter {
      fill: $color-main; } }
  img {
    width: 150px;
    @media screen and (min-width: $screen-lg-min) {
      width: 204px; } } }

.main-nav {
  display: none;
  @media (min-width: $screen-sm-min) {
    display: inline-flex;
    align-items: center;
    float: right;
    padding: 1.3em 0; }
  ul.primary-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    & > li {
      display: inline-block;
      position: relative;
      &.opened {
        .sub-menu {
          opacity: 1;
          pointer-events: auto;
          transform: translateY(0);
          left: 0;
          transition: opacity 0.2s cubic-bezier(0.55, 0, 0.1, 1) 0.1s, transform 0.2s cubic-bezier(0.55, 0, 0.1, 1) 0.1s, left 0s 0s ease; }
        & > a:before {
          width: 100%; } }
      & > a {
        display: inline-block;
        padding: 0.2em .4em;
        text-decoration: none;
        border-radius: 5px;
        font-weight: 700;
        font-size: 14px;
        color: $color-text;
        text-transform: uppercase;
        font-family: $font-main !important;
        &:hover,
        &:focus {
          text-decoration: none;
          color: $color-main; }
        @media screen and (min-width: $screen-md-min) {
          padding: 0.2em 1.35em; } }
      &.menu-item-has-children {
        & > a {
          padding-right: 1.5em;
          &:after {
            content: ' ';
            position: absolute;
            right: 5px;
            top: 50%;
            margin-top: -2px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 5px 5px 0 5px;
            border-color: #222 transparent transparent transparent; } } }
      &.current-menu-item a,
      &.current_page_parent a {
        color: $color-main; } }
    .sub-menu {
      position: absolute;
      top: auto;
      left: -10000px;
      background: #fff;
      margin: 0;
      padding: 6px 0 10px 0;
      list-style: none;
      line-height: 1.2;
      box-shadow: 1.4px 1.4px 6px rgba(2, 2, 3, 0.16);
      border-radius: 0;
      z-index: 999;
      transform: translateY(10px);
      opacity: 0;
      pointer-events: none;
      text-align: left;
      font-size: 0.8rem;
      font-weight: normal;
      transition: left 0.5s 0.5s ease-in-out, opacity 0.2s 0.1s cubic-bezier(0.55, 0, 0.1, 1), transform 0.2s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
      width: auto;
      @media screen and (min-width: $screen-md-min) {
        font-size: 1rem; }
      @media screen and (min-width: $screen-lg-min) {
        font-size: 1.08rem; }
      li {
        display: block; }
      a {
        display: block;
        text-decoration: none;
        font-weight: normal;
        padding: 0.5em 1em;
        white-space: nowrap; }
      li.current-menu-item a,
      a:hover {
        color: $color-main; } } } }

.main-nav__btn {
  display: none;
  .c-btn {
    padding: .6em .8em; }
  @media screen and (min-width: $screen-sm-min) {
    display: block;
    margin-left: .5em; }
  @media screen and (min-width: $screen-md-min) {
    .c-btn {
      padding: 1em 2.5em; } }
  @media screen and (min-width: $screen-lg-min) {
    margin-left: 1.2em; } }


.mob-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    display: block; }
  a {
    display: block;
    color: $color-text;
    border-bottom: 1px solid $color-main;
    padding: 0.5em 1em;
    background: #fff;
    text-decoration: none;
    &:hover {
      text-decoration: none;
      background: lighten(#444444, 10%); } }
  li.current-menu-item a {
    background: $color-main;
    color: #fff; }
  li.menu-item-has-children {
    & > a {
      position: relative;
      &:after {
        content: ' ';
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -2px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 5px 0 5px;
        border-color: #fff transparent transparent transparent; } } }
  .sub-menu {
    margin: 0;
    padding: 0;
    a {
      padding-left: 2em; } } }

.toggle-nav {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 40px;
  height: 40px;
  padding: 0;
  background: none;
  text-indent: -9999px;
  text-align: center;
  border: 0;
  cursor: pointer;
  &:focus {
    outline: none; }
  &:hover {
    background: none; }
  span {
    width: 30px;
    height: 3px;
    background: $color-main;
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -15px;
    margin-top: -2px;
    transition: all .3s ease-in-out;
    opacity: 1; }
  span:first-child {
    margin-top: auto;
    top: 8px; }
  span:last-child {
    margin-top: 0;
    top: 29px; }
  @media (min-width: $screen-sm-min) {
    display: none; } }

.show-nav {
  height: 100vh;
  overflow: hidden;
  .toggle-nav {
    .icon-bar {
      opacity: 0; }
    .icon-bar:first-child {
      opacity: 1;
      display: block;
      top: 17px;
      transform: rotate(45deg); }
    .icon-bar:last-child {
      opacity: 1;
      display: block;
      transform: rotate(-45deg);
      top: 17px; } } }

.browserupgrade {
  text-align: center;
  margin: 0 auto;
  background: #FC0;
  color: #000;
  padding: .8em 0;
  font-size: 14px;
  a {
    color: darken(#FC0, 25%);
    text-decoration: underline;
    &:hover {
      text-decoration: none; } } }
