.u-text-size-xsmall {
  @include fluid-type(11px, 13px);
  line-height: 1.75; }

.u-text-size-small {
  @include fluid-type(13px, 15px);
  line-height: 1.6; }

.u-text-size-large {
  @include fluid-type(17px, 21px);
  line-height: 1.57; }

.u-text-size-xlarge {
  @include fluid-type(24px, 29px);
  line-height: 1.41; }
