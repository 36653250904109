.c-title {
  line-height: $size-line-height-header;
  font-family: $font-header;
  color: $color-headers;
  font-weight: $font-weight-header;
  margin-bottom: .1em;
  @include fluid-type(20px, 24px);
  &:first-child {
    margin-top: 0; } }

.c-title--xxsmall {
  @include fluid-type(14px, 18px); }

.c-title--xsmall {
  @include fluid-type(17px, 21px); }

.c-title--small {
  @include fluid-type(14px, 14px);
  color: $color-main; }

.c-title--large {
  @include fluid-type(26px, 42px); }

.c-title--xlarge {
  @include fluid-type(26px, 40px);
  font-weight: 300; }

.c-title--20 {
  @include fluid-type(16px, 20px);
  margin-bottom: 1em; }

.c-title--30 {
  @include fluid-type(20px, 30px); }

.c-title--margin {
  margin-bottom: .7em; }
