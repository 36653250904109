.c-spacer {
  border: 0;
  margin: 0;
  padding: 0;
  background: transparent;
  border-color: transparent; }

.c-spacer--xs {
  @media screen and (min-width: $screen-sm-min) {
    display: none; } }

.c-spacer--sm {
  display: none;
  @media screen and (min-width: $screen-sm-min) {
    display: block; }
  @media screen and (min-width: $screen-md-min) {
    display: none; } }

.c-spacer--md {
  display: none;
  @media screen and (min-width: $screen-md-min) {
    display: block; }
  @media screen and (min-width: $screen-lg-min) {
    display: none; } }

.c-spacer--lg {
  display: none;
  @media screen and (min-width: $screen-lg-min) {
    display: block; }
  @media screen and (min-width: $screen-xl-min) {
    display: none; } }

.c-spacer--xl {
  display: none;
  @media screen and (min-width: $screen-xl-min) {
    display: block; } }
