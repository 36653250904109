/*!
 * Project: Good Start
 */

/**
 * This is where all of the stylesheets are compiled.
 * They are processed in the order they are imported
 * to ensure the consistent increase of specificity.
 *
 *    ITCSS Methodology: Inverted Triangle CSS
 *
 *    **************   1. Settings
 *     ************    2. Tools
 *      **********     3. Generic
 *       ********      4. Elements
 *        ******       5. Objects
 *         ****        6. Components & Blocks
 *          **         7. Utilities
 *
 * 1. Settings..............Global configuration and variables.
 * 2. Tools.................Functions and mixins.
 * 3. Generic...............Ground zero styles.
 *                          (normalizing.css, box-sizing etc...)
 * 4. Elements..............Unclassed (bare) HTML element
 *                          (like H1, Ul, A etc...)
 * 5. Objects...............Common non-cosmetic structural design patterns.
 *                          e.g containers, rows, grids, colums etc...
 * 6. Components & Blocks...Specific cosmetic elements of UI.
 *                          e.g. buttons, forms, header, footer etc...
 * 7. Utilities.............Helpers and overrides.
 *
 *    Modules...............Multi-part components.
 *                          e.g. Navbar (HTML, CSS and JS).
 *    Shame.................All the CSS, hacks and things we are not proud of.
 */

// Settings
@import "settings/_variables.sass";

// Tools
@import "tools/_clearfix.sass";
@import "tools/_fluid-typography.scss";
@import "tools/_font-smoothing.scss";
@import "tools/_recaptcha-fix.sass";
@import "tools/_wordpress-styles.sass";
@import '../../node_modules/object-fit-images/preprocessors/mixin.sass'; // Object fit mixin
@import '../../node_modules/jeet/scss/index.scss'; // Grid mixins

// Generic
@import "generic/_box-sizing.sass";
@import "generic/_font-face.sass";
@import "generic/_normalize.scss";
@import "generic/_print.scss";

// Elements
@import "elements/_blockquote.sass";
@import "elements/_document.sass";
@import "elements/_headers.sass";
@import "elements/_inputs.sass";
@import "elements/_links.sass";
@import "elements/_lists.sass";
@import "elements/_media.sass";
@import "elements/_paragraphs.sass";
@import "elements/_pre.sass";
@import "elements/_tables.sass";

// Objects
@import '../../node_modules/bootstrap/scss/bootstrap-grid'; // Grid
@import "objects/_cookie-bar.sass";
@import "objects/_footer.sass";
@import "objects/_global-layout.sass";
@import "objects/_grid.scss";
@import "objects/_header.sass";
@import "objects/_scroll-to-top.sass";
@import "objects/_wrapers.sass";
@import "objects/header-panel.sass";

// Components
@import "components/_1_title.sass";
@import "components/_accordion.sass";
@import "components/_alerts.sass";
@import "components/_buttons.sass";
@import "components/_horizontal-line.sass";
@import "components/_map.sass";
@import "components/_pagination.sass";
@import "components/_spacer.sass";
@import '../../node_modules/magnific-popup/src/css/main'; // Lightbox
@import '../../node_modules/leaflet/dist/leaflet'; // Maps
@import '../../node_modules/aos/src/sass/aos'; // Animations
@import '../../node_modules/slick-carousel/slick/slick.scss'; // Carousel
//@import '../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome' // Font awesome
//@import '../../node_modules/@fortawesome/fontawesome-free/scss/solid' // Font awesome

// Blocks
@import "blocks/_full-width-content-image.sass";
@import "blocks/_full-width-images.sass";
@import "blocks/avatar.sass";
@import "blocks/big-search.sass";
@import "blocks/black-list.sass";
@import "blocks/call-to-action.sass";
@import "blocks/numbers.sass";
@import "blocks/slider-image-text.sass";
@import "blocks/slider-people.sass";
@import "blocks/stages.sass";

// Utilities
@import "utilities/_alignment.sass";
@import "utilities/_colors.sass";
@import "utilities/_lazyload.sass";
@import "utilities/_spacers.sass";
@import "utilities/_text-sizes.sass";
@import "utilities/_text-weight.sass";
@import "utilities/_video-responsive.sass";
@import "utilities/_visibility.sass";
@import "utilities/overrides/_aos.sass";
@import "utilities/overrides/_leaflet.sass";
@import "utilities/overrides/_magnific-popup.sass";
@import "utilities/overrides/_slick-carousel.sass";

// Pages
@import "pages/_404.sass";
@import "pages/about.sass";
@import "pages/contact.sass";
@import "pages/edit-avatar.scss";
@import "pages/edit-profile.sass";
@import "pages/how-start.sass";
@import "pages/login.sass";
@import "pages/main-page-login.sass";
@import "pages/message.sass";
@import "pages/pair.sass";
@import "pages/range-handles.scss";
@import "pages/register.sass";
@import "pages/regulation.sass";
@import "pages/search-results.sass";
@import "pages/settings-profile.sass";
@import "pages/upgrade-profile.sass";
@import "pages/user-profile.sass";
