.about {
  padding: 8em 0;
  @media screen and (min-width: $screen-sm-min) {
    padding: 12em 0 8em; } }

.about__header, .about__text {
  text-align: center;
  max-width: 909px;
  margin: 0 auto;
  margin-bottom: 1em !important; }

.about__cols {
  @include column(1/1);
  margin-bottom: 1em; }

.about__cols-wrapper {
  max-width: 1065px;
  margin: 0 auto 2em; }

.about__cols-el {
  @include column(1/1);
  margin-bottom: 1.9em;
  h3 {
    margin-bottom: .5em; }
  img {
    margin-bottom: 1.4em; }
  p {
    font-size: 16px;
    line-height: 1.9; }
  @media screen and (min-width: $screen-sm-min) {
    @include column(1/2, $cycle: 2, $gutter: 14); } }

.about__text-bottom {
  text-align: center;
  display: block;
  max-width: 958px;
  margin: 0 auto; }
