.upgrade-profile {
  margin: 5em 0; }

.upgrade-profile__wrapper {
  text-align: center;
  margin-top: 3em;
  margin-bottom: 3em;
  h2 {
    margin-bottom: 1em; } }

.upgrade-profile__btns {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .c-btn {
    margin: .4em .4em; } }
