// Styles for elements generated by WordPress
.comment-list {
  list-style: none;
  margin: 0;
  padding: 0;
  text-indent: 0;
  ol {
    list-style: none; } }

li.comment {
  border-bottom: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(5,1,7,.11);
  margin-bottom: 1em;
  padding: 1em;
  div.vcard {
    font-weight: 400;
    img.avatar {
      border: 5px solid #ccc;
      float: right;
      margin: 0 0 20px 20px; } }
  div.comment-metadata {
    font-size: 11px;
    margin-bottom: 0.7em; }
  ul {
    font-size: 12px;
    list-style: none;
    margin: 0 0 0 20px; }
  div.reply {
    font-size: 11px;
    a {
      font-weight: bold; }
    ul.children {
      list-style: none;
      margin: 12px;
      text-indent: 0;
      li.depth-2 {
        border-left: 5px solid #ccc;
        margin: 0 0 10px 10px; }
      li.depth-3 {
        border-left: 5px solid #bbb;
        margin: 0 0 10px 10px; }
      li.depth-4 {
        border-left: 5px solid #aaa;
        margin: 0 0 10px 10px; } } } }

.comment-body {
  margin-bottom: 1em; }

/* Contact Form 7 Styles */
div.wpcf7-response-output {
  margin: 20px 0;
  border: 1px solid transparent;
  border-radius: 3px;
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
  padding: 0.3em 0.6em; }

span.wpcf7-not-valid-tip {
  border: 1px solid transparent;
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
  margin-top: 4px;
  padding: 0.3em 0.6em;
  font-size: 0.8em; }

div.wpcf7-validation-errors,
div.wpcf7-spam-blocked {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
  padding: 0.8em 1em; }

.wpcf7-validation-errors {
  clear: both;
  margin-top: 1em; }

.contact--recaptcha {
  padding-top: 1em; }

div.wpcf7-mail-sent-ok {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
  padding: 0.8em 1em; }

span.wpcf7-list-item {
  margin-left: 0; }

div.wpcf7 .ajax-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  background: url(../img/icons/spinner.svg);
  width: 200px;
  height: 200px;
  background-color: rgba(#fff, 0.9);
  border-radius: 100%;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.16); }

// Pagination
.wp-pagenavi {
  padding-top: 10px;
  padding-bottom: 2px;
  @media screen and (min-width: $screen-sm-min) {
    padding-top: 24px; }
  .pages,
  .nextpostslink,
  .previouspostslink {
    display: none; }
  .extend {
    display: inline-block;
    margin-right: 14px;
    color: #A3B3C0; }
  .page,
  .current,
  .first,
  .last,
  .extend {
    color: #a6b2bf;
    display: inline-block;
    height: 40px;
    padding: 0 10px;
    margin-right: 14px;
    line-height: 38px;
    min-width: 35px;
    text-align: center;
    transition: all 0.3s;
    background: #FFFFFF;
    text-shadow: none;
    border: 1px solid #A3B3C0;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    margin-bottom: 1em;
    @media screen and (min-width: $screen-sm-min) {
      height: 56px;
      line-height: 54px;
      min-width: 41px;
      font-size: 18px; }
    &:hover,
    &:focus {
      text-decoration: none;
      color: darken(#a6b2bf, 20%);
      border-color: darken(#A3B3C0, 20%); } }
  .current {
    color: $color-main;
    background: #FFFFFF;
    border-color: $color-main;
    &:hover {
      color: $color-main;
      background: #fff;
      border-color: $color-main; } } }

.alignleft {
  margin-bottom: 20px;
  @media (min-width: $screen-sm-min) {
    margin-bottom: 25px;
    float: left;
    margin-right: 42px;
    display: inline; }
  @media (min-width: $screen-md-min) {
    margin-bottom: 45px; } }

.alignright {
  margin-bottom: 20px;
  @media (min-width: $screen-sm-min) {
    display: inline;
    float: right;
    margin-left: 42px;
    margin-bottom: 25px; }
  @media (min-width: $screen-md-min) {
    margin-bottom: 45px; } }

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.wp-caption {
  margin-bottom: 1.5em;
  max-width: 100%;
  p:first-child {
    margin-bottom: 0.5em; } }

.wp-caption img[class*="wp-image-"] {
  display: block;
  margin: 0 auto; }

.wp-caption-text {
  text-align: center; }

.wp-caption .wp-caption-text {
  margin: 0 0 1em;
  font-size: 0.8rem; }

.gallery {
  margin-bottom: 1.5em; }

.gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%; }

.gallery-columns-2 .gallery-item {
  max-width: 50%; }

.gallery-columns-3 .gallery-item {
  max-width: 33.33%; }

.gallery-columns-4 .gallery-item {
  max-width: 25%; }

.gallery-columns-5 .gallery-item {
  max-width: 20%; }

.gallery-columns-6 .gallery-item {
  max-width: 16.66%; }

.gallery-columns-7 .gallery-item {
  max-width: 14.28%; }

.gallery-columns-8 .gallery-item {
  max-width: 12.5%; }

.gallery-columns-9 .gallery-item {
  max-width: 11.11%; }

.gallery-caption {
  display: block; }

.entry-meta {
  margin-bottom: 15px;
  font-style: italic;
  font-size: 0.85em; }

.entry-footer {
  padding: 1em 0; }

.entry-back {
  text-align: center;
  padding-top: 3em; }

.cat-links,
.tags-links {
  display: block;
  margin-bottom: 0.4em; }
