.stages {
  padding: 2em 0;
  @media screen and (min-width: $screen-sm-min) {
    padding: 3.2em 0 4.2em; } }

.stages__title {
  text-align: center;
  margin-bottom: 2.5em; }

.stages__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: $screen-sm-min) {
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    overflow: hidden; }
  @media screen and (min-width: $screen-md-min) {
    background-image: url('../img/icons/dotted.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain; } }

.stages__el {
  max-width: 184px;
  text-align: center;
  margin-bottom: 1.3em;
  @media screen and (min-width: $screen-sm-min) {
    margin-bottom: 0; } }

.stages__el-nr {
  margin: 0 auto;
  font-size: 130px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  color: $color-tertiary;
  background-color: $color-secondary;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  border: 8px solid #000;
  @media screen and (min-width: $screen-md-min) {
    width: 175px;
    height: 175px;
    font-size: 150px; }
  img {
    position: absolute;
    width: 95px; } }

.stages__el-text {
  margin-top: 1.4em;
  @include fluid-type(14px, 16px);
  line-height: 1.2; }
