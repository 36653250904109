/**
 * Links
 */

a {
  color: $color-link;
  text-decoration: none;
  background-color: transparent; // Remove the gray background on active links in IE 10.
  -webkit-text-decoration-skip: objects; // Remove gaps in links underline in iOS 8+ and Safari 8+
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  &:hover {
    color: $color-link-hover;
    text-decoration: underline; } }

a[href$=jpg], a[href$=jpeg], a[href$=jpe], a[href$=png], a[href$=gif] {
  &:focus {
    outline: none; } }
