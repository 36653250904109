html {
  font-family: $font-main;
  font-size: $size-text-max;
  color: $color-text;
  line-height: $size-line-height;
  font-weight: $font-weight-standard;
  @include fluid-type($size-text-min, $size-text-max);
  background: $color-site-background;
  @include font-smoothing(on); }

body {
  padding: 0;
  margin: 0;
  background-image: url('../img/content/bg.svg');
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  &.show-screen-size {
    &:after {
      display: none;
      position: fixed;
      left: 50%;
      bottom: 0px;
      width: 160px;
      background: #000;
      color: #fff;
      content: '[TINY] 0px-479px';
      text-align: center;
      padding: 5px;
      font-size: 12px;
      z-index: 9999;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      margin-left: -80px;
      @media (min-width: $screen-mini-min) {
        content: "[MINI] 480px-575px"; }
      @media (min-width: $screen-xs-min) {
        content: "[XS] 576px-767px"; }
      @media (min-width: $screen-sm-min) {
        content: "[SM] 768px-991px"; }
      @media (min-width: $screen-md-min) {
        content: "[MD] 992px-1199px"; }
      @media (min-width: $screen-lg-min) {
        content: "[LG] 1200px-1399px"; }
      @media (min-width: $screen-xl-min) {
        content: "[XL] 1400px-1799px"; }
      @media (min-width: $screen-xxl-min) {
        content: "[XXL] 1800px-∞"; } } } }
