.message-reply {
  margin: 5em 0; }

.message-reply__wrapper {
  max-width: 500px;
  margin: 2em auto 0; }

.message-reply__line {
  width: 100%;
  @extend %clearfix; }

.message-reply--send {
  background: $color-main;
  padding: 0.5em;
  margin: .3em 0;
  color: #fff;
  border-radius: 10px;
  border: 2px solid $color-main;
  max-width: 90%;
  float: right;
  display: block; }

.message-reply--recive {
  background: #fff;
  padding: 0.5em;
  margin: .3em 0;
  color: $color-text;
  border-radius: 10px;
  border: 2px solid $color-main;
  max-width: 90%;
  float: left; }


.message-reply__send {
  text-align: center; }


.message-list__table-head {
  @extend %clearfix;
  border-bottom: 2px solid $color-main;
  padding-bottom: .5em; }

.message1, .message2, .message3 {
  @include column(1/3, $cycle: 3); }

.message3 {
  text-align: right; }

.message-list {
  margin: 5em 0;
  .message-list__table-el:nth-child(2n+1) {
    background: transparent !important; } }

.message-list__table-el {
  padding: .5em;
  p {
    margin-bottom: 0; }
  @extend %clearfix;
  background: lighten($color-tertiary, 15%); }
