.popup-youtube, .popup-vimeo, .popup-gmaps {
  &:focus {
    outline: none; } }

.mfp-iframe-holder .mfp-content {
  max-width: 90vw;
  @media screen and (min-width: $screen-xl-min) {
    max-width: 80vw; } }

.mfp-with-fade {
  // before-open state
  .mfp-content,
  .mfp-arrow,
  &.mfp-bg {
    opacity: 0;
    transition: opacity 0.3s ease-out; }

  // open state
  &.mfp-ready {
    .mfp-content,
    .mfp-arrow {
      opacity: 1; }
    &.mfp-bg {
      opacity: 0.8; } }

  // closed state
  &.mfp-removing {
    .mfp-content,
    .mfp-arrow,
    &.mfp-bg {
      opacity: 0; } } }

.mfp-zoom-out {
  /* start state */
  .mfp-with-anim {
    opacity: 0;
    transition: all 0.3s ease-in-out;
    transform: scale(1.3); }
  &.mfp-bg {
    opacity: 0;
    transition: all 0.3s ease-out; }
  /* animate in */
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: scale(1); }
    &.mfp-bg {
      opacity: 0.8; } }
  /* animate out */
  &.mfp-removing {
    .mfp-with-anim {
      transform: scale(1.3);
      opacity: 0; }
    &.mfp-bg {
      opacity: 0; } } }

.white-popup {
  position: relative;
  background: #FFF;
  padding: 20px;
  width: auto;
  max-width: 500px;
  margin: 20px auto;
  box-shadow: 2.9px 4px 5px rgba(5,1,7,.19);
  @include fluid-type(16px, 18px); }
